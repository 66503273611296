import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import NumberFormat from "react-number-format";
import axios from "axios";
import {
  amplitudeBtnEvent,
  ampRabbitMQBtnEvent,
  amplitudePopUpEvent,
  ampRabbitMQPopUpEvent,
  getParam,
} from "../../utils";
import { validateCardNumber } from "../../utils/payments";

const Payments = ({
  plan_id,
  utm_source,
  aff_id,
  planData,
  total_user_coins,
  textData,
  payments,
  urlparams,
  identity,
  product_id,
  platform_id,
  coupon_code,
  pushbar,
  setPushbar,
  tabHideShow,
  setTabHideShow,
  isFreeTrial,
}) => {
  const [isAgree, setIsAgree] = useState(true);
  /*
     //control autopay checkbox for users
    const planType=getParam(urlparams, 'plan_type').toLocaleLowerCase();
    let isValidUser=false;
    let allowCheckConsent=[];
    if(allowCheckConsent.includes(identity) && planType!=="event" && !/vod/.test(planType)){
        isValidUser=true;
    }
    */
  let isValidUser = false;
  const planType = getParam(urlparams, "plan_type")?.toLocaleLowerCase();
  /* exclude autopay consent checkbox for 3 days plan i.e plan id 57 */
  if (
    planType !== "event" &&
    !/vod/.test(planType) &&
    plan_id !== "57" &&
    plan_id !== "10"
  ) {
    isValidUser = true;
  }

  const [isValidName, setValidName] = useState(false);
  const [isValidNameSp, setValidNameSp] = useState(false);
  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidMonth, setValidMonth] = useState(false);
  const [isValidCvv, setValidCvv] = useState(false);
  const [freeTrialCCValid, setfreeTrialCCValid] = useState(false);

  // console.log(
  //   "urlparams platform_id::",
  //   new URLSearchParams(urlparams).get("platform_id")
  // );

  /* Params */
  const user_upgradable = new URLSearchParams(urlparams).get("upgradable");
  // const platform_id = new URLSearchParams(urlparams).get("platform_id");
  const country = new URLSearchParams(urlparams).get("country");
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventGatwayTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  var showCoinsTab = false;
  var plan_price_for_enable_coins = false;
  var amt_to_enable_coins = 0;
  var current_user_coins = total_user_coins;
  //   var current_user_coins = 30000;

  if (planData?.plan_price) {
    amt_to_enable_coins = planData?.plan_price * 40;
    //console.log(amt_to_enable_coins,'amt_to_enable_coins');
    if (current_user_coins >= amt_to_enable_coins) {
      plan_price_for_enable_coins = true;
    }
  }
  // console.log("platform_id::", platform_id);

  //   if(planData.plan_name=="PTVOD" ||
  //     planData.plan_name=="TVOD" ||
  //     planData.plan_name=="PTVOD150"
  //   ){
  //     showCoinsTab = false;
  //   }else{

  //     if(plan_price_for_enable_coins){

  //         showCoinsTab = true;

  //     }

  //   }
  if (/Rent/.test(planData?.plan_dname) || /event/.test(planData?.plan_dname)) {
    showCoinsTab = false;
  } else {
    if (plan_price_for_enable_coins) {
      showCoinsTab = true;
    }
    if (user_upgradable === "1" && showCoinsTab) {
      showCoinsTab = false;
    } else if (user_upgradable === "1" && showCoinsTab === false) {
      showCoinsTab = false;
    } else if (user_upgradable === "0" && showCoinsTab === true) {
      showCoinsTab = true;
    }
  }

  // console.log(planData,'planData_mobile')

  const gaEventTracker = useAnalyticsEventGatwayTracker("Credit Debit Card");
  const gaEventTrackerHC = useAnalyticsEventGatwayTracker("Hungama Coins");
  //const gaEventTrackerWallet = useAnalyticsEventGatwayTracker('Wallet');

  // var ua = navigator.userAgent.toLowerCase();
  // var macSafari = false;
  // var iphoneSafari = false;
  // if (ua.indexOf('safari') !== -1) {
  //     if (ua.indexOf('chrome') > -1) {
  //       macSafari = false;
  //     } else {
  //       macSafari = true;
  //     }
  //   }
  //   console.log('macSafari',macSafari)
  //   console.log('iphoneSafari',iphoneSafari)
  // if( /iPhone|iPad|iPod|/i.test(navigator.userAgent) ) {
  //     // some code..
  //     iphoneSafari = true;
  //    // console.log("device:"+navigator.userAgent);
  // }

  var showGoogleWallet = false;
  if (platform_id === "1") {
    showGoogleWallet = true;
  }

  const validateCardNumberLoc = async (e, isFreeTrial) => {
    gaEventTracker("pay_now", planData?.plan_name + "_" + planData?.plan_price);
    var nameRegex = /^[A-Za-z\s]+$/;
    setValidName(false);
    setValidNameSp(false);
    setValidCardNumber(false);
    if (document?.getElementById("cardHolderName")?.value === "") {
      setValidName(true);
      e.preventDefault();
      return false;
    } else if (document?.getElementById("cardHolderName")?.value !== "") {
      if (!nameRegex?.test(document?.getElementById("cardHolderName")?.value)) {
        setValidName(false);
        setValidNameSp(true);
        e.preventDefault();
        return false;
      }
    }
    if (document.getElementById("cardNumber")?.value === "") {
      setValidCardNumber(true);
      setValidName(false);
      e.preventDefault();
      return false;
    }

    if (validateCardNumber(formData?.cardNumber?.replace(/ /g, ""))) {
      //console.log("Valid Card Number");
      // amplitudeBtnEvent(identity, "Btn_Submit_CCDC", product_id, platform_id);
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_Submit_CCDC"
      );
    } else {
      setValidCardNumber(true);
      setValidName(false);
      //pay_validate = true;
      e.preventDefault();
      return false;
    }

    var result = true;
    var errMsg = "";
    var expire = document?.getElementById("expiryDate")?.value;
    if (!expire.match(/(0[1-9]|1[0-2])[/][0-9]{2}/)) {
      errMsg += "The expire date formate is not correct!\n";
      result = false;
    } else {
      // get current year and month
      var d = new Date();
      var currentYear = d.getFullYear();
      var currentMonth = d.getMonth() + 1;
      // get parts of the expiration date
      var parts = expire.split("/");
      var year = parseInt(parts[1], 10) + 2000;
      var month = parseInt(parts[0], 10);
      // compare the dates
      if (
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        errMsg += "The expiry date has passed.\n";
        result = false;
      }
    }
    console.log(errMsg);
    if (!result) {
      setValidMonth(true);
      e.preventDefault();
      return false;
    } else {
      setValidMonth(false);
    }
    if (document.getElementById("cvv")?.value === "") {
      setValidMonth(false);
      setValidCvv(true);
      e.preventDefault();
      return false;
    }

    //free trial
    if (
      isFreeTrial?.status === false &&
      isFreeTrial?.plan_id === plan_id &&
      isCreditDebit === "DC"
    ) {
      // console.log("wrong DC for freetrial");
      setValidCvv(false);
      setfreeTrialCCValid(true);
      e.preventDefault();
      return false;
    } else {
      setfreeTrialCCValid(false);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e?.target;
    // console.log(name);
    let data = { ...formData };
    // console.log(total_user_coins);
    data[name] = value;

    setFormData(data);
  };

  const handlePushbar = (e) => {
    console.log(e);
    const name = e?.target?.getAttribute("data-name");
    if (name === "verifyRedeemCoins") {
      // amplitudeBtnEvent(identity, "Btn_Cancel_Coins", product_id, platform_id);
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_Cancel_Coins"
      );
      gaEventTrackerHC("Hungama Coins", "Cancel");
    }
    let value = e?.target?.getAttribute("value");
    setPushbar((prev) => {
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
  };

  const hungamaCoins = (e) => {
    // console.log(planData);
    // amplitudeBtnEvent(identity, "Btn_Select_Coins", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Select_Coins"
    );

    /*
    amplitudePopUpEvent(
      identity,
      {
        Source: utm_source,
        "Plan Name": planData?.plan_name,
        "Plan Validity": planData?.plan_valid,
        "Plan Value": planData?.plan_price?.toFixed(2),
        "Plan Currency": planData?.plan_currency,
        "Plan ID": plan_id,
        "Affiliate ID": aff_id,
        Action: "Redeem_Coins_Confirmation",
      },
      product_id,
      platform_id
    );
    */

    ampRabbitMQPopUpEvent({
      identity: identity,
      Source: utm_source,
      "Plan Name": planData?.plan_name,
      "Plan Validity": planData?.plan_valid,
      "Plan Value": planData?.plan_price?.toFixed(2),
      "Plan Currency": planData?.plan_currency,
      "Plan ID": plan_id,
      "Affiliate ID": aff_id,
      product_id: product_id,
      platform_id: platform_id,
      Action: "Redeem_Coins_Confirmation",
    });

    // console.log(e)
    const name = "verifyRedeemCoins";
    let value = e;
    setPushbar((prev) => {
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
  };

  // const [isLoading, setLoading] = useState(true);
  const [isCategoryCC, setIsCategoryCC] = useState(false);
  const [isCreditDebit, setIsCreditDebit] = useState("");
  // const [creditCardShow, setCreditCardShow] = useState(false);
  const [formData, setFormData] = useState({
    couponVerified: false,
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    upiid: "",
  });
  // const [pushbar, setPushbar] = useState({ verifyRedeemCoins: false });

  const handleOtherPayments = (e) => {
    if (e?.target?.getAttribute("data-name") === "verifyRedeemCoins") {
      // amplitudeBtnEvent(identity, "Btn_Submit_Coins", product_id, platform_id);
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_Submit_Coins"
      );
      gaEventTrackerHC("Hungama Coins", "Redeem Now");
    } else {
      // amplitudeBtnEvent(identity, "Btn_GoogleWallet", product_id, platform_id);
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_GoogleWallet"
      );
      gaEventTracker(
        "google_wallet",
        e?.target?.getAttribute("plan_details_id")
      );
    }

    const payment_id = e?.target?.getAttribute("payment_id");
    const plan_details_id = e?.target?.getAttribute("plan_details_id");
    const storedWebsite = localStorage.getItem("website");
    let url;
    if (payment_id === "9") {
      if (storedWebsite == 'true' || storedWebsite == true) {
        url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?tab=cct&payment_id=${payment_id}&identity=${identity}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&coupon_code=${coupon_code}&website=true`;
      }
      else {
        url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?tab=cct&payment_id=${payment_id}&identity=${identity}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&coupon_code=${coupon_code}`;
      }
    } else {
      if (storedWebsite == 'true' || storedWebsite == true) {
        url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${payment_id}&identity=${identity}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&coupon_code=${coupon_code}&website=true`;
      }
      else {
        url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${payment_id}&identity=${identity}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&coupon_code=${coupon_code}`;
      }
    }
    //console.log(url);
    window.location.href = url;
  };
  // const handleCreditCard = () => {
  //     // console.log("handleCreditCard");
  //     if (creditCardShow === false) {
  //         setCreditCardShow(true);
  //     } else {
  //         setCreditCardShow(false);
  //     }
  // }
  // useEffect(() => {
  //   console.log("isCreditDebit:", isCreditDebit);

  // }, [isCreditDebit]);
  // useEffect(() => {
  let isCardCheck = false;
  isCardCheck = formData?.cardNumber?.trim().length === 8 ? true : false;

  if (isCardCheck) {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=15&product_id=1&rtype=check_card_type&card_bin=${formData.cardNumber}`,
    })
      .then((res) => {
        // console.log(res.data);
        const cardType = res?.data?.cardCategory;

        // console.log('res.data?.cardCategory::',cardType,'res.data.cardType',res.data.cardType,typeof cardType,typeof res.data.cardType );
        setIsCreditDebit(cardType);
        if (cardType && cardType === "CC") {
          setIsCategoryCC(true);
        } else {
          setIsCategoryCC(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //     var showCoinsTab = false;
  //     var plan_price_for_enable_coins = false;
  //     var amt_to_enable_coins = 0;
  //     var current_user_coins = total_user_coins;

  //     if(planData.plan_price){
  //       amt_to_enable_coins = planData.plan_price * 40
  //      // console.log(amt_to_enable_coins,'amt_to_enable_coins');
  //       if(current_user_coins >= amt_to_enable_coins){
  //           plan_price_for_enable_coins = true;
  //       }
  //     }
  //     //console.log(planData.plan_name,'plan_name');
  //     if(/vod/.test(planData.plan_name) || /VALUE PACK/.test(planData.plan_name)){
  //         showCoinsTab = false;
  //       }else{
  //         if(plan_price_for_enable_coins){
  //           showCoinsTab = true;
  //       }
  //   }

  //console.log('showCoinsTab:'+showCoinsTab);

  // setLoading(false);
  // }, [formData.cardNumber])

  // if (formData.cardNumber.trim().length < 7) isCardCheck = true;

  return (
    <>
      {payments &&
        payments?.length > 0 &&
        payments
          ?.sort(
            (a, b) =>
              parseFloat(a?.display_order) - parseFloat(b?.display_order)
          )
          .map((data, i) => (
            <div key={i}>
              {data?.payment_name === "Credit Card" && country === "IN" && (
                <>
                  <div className="pay-mode-box" key={i}>
                    <div
                      className="cchead flex1"
                      onClick={(e) =>
                        setTabHideShow((prevState) => ({
                          ...prevState,
                          upiTab: false,
                          ccTab: !tabHideShow?.ccTab,
                        }))
                      }
                    >
                      <p
                        className="p-t1"
                        onClick={(e) =>
                          setTabHideShow((prevState) => ({
                            ...prevState,
                            upiTab: false,
                            ccTab: !tabHideShow?.ccTab,
                          }))
                        }
                      >
                        {isFreeTrial?.status === false &&
                          isFreeTrial?.plan_id === plan_id
                          ? textData?.pay_cc_dc_trialoffer_label
                          : textData?.pay_cc_dc_label}
                      </p>
                      <div className="down-arrow pointer">
                        <img
                          onClick={(e) =>
                            setTabHideShow((prevState) => ({
                              ...prevState,
                              upiTab: false,
                              ccTab: !tabHideShow?.ccTab,
                            }))
                          }
                          src={
                            process.env.REACT_APP_ASSETS_URL +
                            "/mobile/images/down-arrow.svg"
                          }
                          alt="downarrow"
                        />
                      </div>
                    </div>
                    <div
                      id="cc-details-box"
                      style={{
                        display: !tabHideShow?.ccTab ? "none" : "block",
                      }}
                    >
                      <form
                        onSubmit={(e) => validateCardNumberLoc(e, isFreeTrial)}
                        action={`${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&type=charge&${urlparams}&coupon_code=${coupon_code}`}
                        method="POST"
                      >
                        <input
                          type="hidden"
                          value={`${data?.payment_id}`}
                          name="hiddenpayment_id"
                          id="hiddenpayment_id"
                        />
                        <input
                          type="hidden"
                          value={`${data?.plan_details_id}`}
                          name="hiddenplan_details_id"
                          id="hiddenplan_details_id"
                        />

                        <div className="ccrow">
                          <input
                            type="text"
                            value={formData?.cardHolderName}
                            name="cardHolderName"
                            id="cardHolderName"
                            onChange={(e) => handleInput(e)}
                            placeholder={textData?.pay_cc_name_label}
                            className="ccinput"
                          />
                        </div>
                        {isValidName && (
                          <p style={{ color: "red" }}>
                            {/* Please Enter Card Holder Name */}
                            {textData?.pay_cc_enter_card_holder_name_error}
                          </p>
                        )}
                        {isValidNameSp && (
                          <p style={{ color: "red" }}>
                            {/* Please Enter Valid Card Holder Name */}
                            {textData?.pay_cc_enter_card_holder_name_error}
                          </p>
                        )}

                        <div className="ccrow">
                          <NumberFormat
                            className="ccinput"
                            value={formData.cardNumber}
                            name="cardNumber"
                            id="cardNumber"
                            onChange={(e) => handleInput(e)}
                            placeholder={textData?.pay_cc_number_label_mob}
                            format="#### #### #### ####"
                            autoComplete="off"
                          />
                        </div>
                        {isValidCardNumber && (
                          <p style={{ color: "red" }}>
                            {/* Please Enter Valid Card Number */}
                            {textData?.pay_cc_enter_valid_card_umber_error}
                          </p>
                        )}

                        <div className="ccrow">
                          <div className="cc-left-box">
                            <NumberFormat
                              value={formData.expiryDate}
                              id="expiryDate"
                              name="expiryDate"
                              onChange={(e) => handleInput(e)}
                              placeholder={
                                textData?.pay_cc_valid_thru_placeholder
                              }
                              className="ccinput"
                              format="##/##"
                              mask={["M", "M", "Y", "Y"]}
                            />
                            {isValidMonth && (
                              <p style={{ color: "red" }}>
                                {/* Please Enter Valid Expiry Date */}
                                {textData?.pay_cc_valid_expiry_error}
                              </p>
                            )}
                          </div>
                          <div className="cc-right-box">
                            {/* <input maxLength="4" type="password" value={formData.cvv} id="cvv" name="cvv" onChange={e => handleInput(e)} className="ccinput" placeholder={textData?.secondLang?.pay_cc_cvv_placeholder ? textData?.secondLang?.pay_cc_cvv_placeholder : textData?.default?.pay_cc_cvv_placeholder} /> */}
                            <NumberFormat
                              maxLength="4"
                              type="password"
                              value={formData.cvv}
                              id="cvv"
                              name="cvv"
                              onChange={(e) => handleInput(e)}
                              className="ccinput"
                              placeholder={textData?.pay_cc_cvv_label}
                            ></NumberFormat>
                            {isValidCvv && (
                              <p style={{ color: "red" }}>
                                {/* Please Enter CVV Number */}
                                {textData?.pay_please_enter_cvv_number_error}
                              </p>
                            )}
                          </div>
                          <input
                            type="hidden"
                            name="pg"
                            value={isCreditDebit}
                          />
                        </div>

                        <div className="ccrow">
                          {
                            // isCategoryCC && formData.cardNumber.trim() > 8&&
                            // <span className="autopay">auto pay goes here { console.log(isCategoryCC)}</span>

                            isCategoryCC ? (
                              <span className="autopay"></span>
                            ) : (
                              <div></div>
                            )
                          }
                        </div>
                        <div className="ccrow">
                          {freeTrialCCValid && (
                            <p
                              style={{
                                color: "red",
                                marginBottom: "10px",
                                marginLeft: "12px",
                              }}
                            >
                              {textData?.pay_enter_only_credit_card}
                            </p>
                          )}
                          <button
                            disabled={isAgree === false ? true : false}
                            type="submit"
                            plan_details_id={data.plan_details_id}
                            payment_id={data.payment_id}
                            className="button-blue w-100"
                          >
                            <img
                              src={
                                process.env.REACT_APP_ASSETS_URL +
                                "/mobile/images/paynow.svg"
                              }
                              alt="pay now"
                              class="button-icon"
                            />{" "}
                            {textData?.pay_paynow}
                          </button>
                        </div>
                        <div className="savesecurepayment">
                          <img
                            src={
                              process.env.REACT_APP_ASSETS_URL +
                              "/mobile/images/lock-icon.svg"
                            }
                            alt="lockicon"
                            className="lockicon"
                          />{" "}
                          {textData?.pay_secure_text}
                        </div>
                        {isValidUser === true ? (
                          <div className="agree-row">
                            <label>
                              <input
                                onChange={(e) => {
                                  if (!e.target.checked) {
                                    setIsAgree(false);
                                  } else {
                                    setIsAgree(true);
                                  }
                                }}
                                type="checkbox"
                                name="checkbox"
                                value="value"
                                checked={isAgree}
                              />
                              {/* I hereby agree and authorize Hungama to debit my
                              account and setup standing instruction. */}
                              {textData?.pay_consent}
                            </label>
                            {isAgree === false && (
                              <p className="error-msg">
                                {/* Please provide your consent to proceed */}
                                {textData?.pay_consent_error}
                              </p>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                  </div>
                  {/* <aside style={{pointerEvents:pushbar.verifyRedeemCoins?"auto":"none"}} className={pushbar.verifyRedeemCoins ? "pushbar from_bottom ht77 opened" : "pushbar from_bottom ht77"}>
                                        <div className="pop-up-wrapper">
                                            <div className="upi-popup-content">
                                                {/* <div className="p-paymenticon">
                                                    <div className="p-payicon-border">
                                                        <img src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/group.svg"} alt="coins icon" className="p-payicon" />
                                                    </div>
                                                </div> 
                                                <p className="p-txt1">SELECT VALID THRU</p>
                                                 <p className="p-upiid fontsize">You are about to .....?</p> 
                                                <Datepicker
                                                    controls={['date']}
                                                    showOnClick={false}
                                                    showOnFocus={false}
                                                    isOpen={openPicker}
                                                    onClose={onClose}
                                                    touchUi={true}
                                                    inputComponent="input"
                                                    inputProps={props}
                                                />
                                                <div className="p-paybtnbox">
                                                    <div className="p-cancel-btn pointer" data-name="verifyRedeemCoins" value={false} onClick={e => handlePushbar(e)}><i data-name="verifyRedeemCoins" value={false} onClick={e => handlePushbar(e)} className="icon-Remove button-icon" ></i>Cancel</div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </aside> */}
                </>
              )}
              {/* coin payment made hidden on 17-03-23 */}
              {/* { data?.payment_name === "Hungama Coins" &&
                showCoinsTab &&
                data?.payment_logo && (
                  <>
                    <div
                      className="pay-mode-box"
                      key={i}
                      onClick={(e) => hungamaCoins("true")}
                    >
                      <div className="google-play-store-box flex1">
                        <div className="gpsc">
                          <img
                            src={data?.payment_logo}
                            alt="play store"
                            className="gplayimg"
                          />
                          {textData?.pay_hungama_coins_label}
                        </div>
                        <div className="nextarrow pointer">
                          <span className="hungama-coins">{data?.coins}</span>
                          <img
                            src={
                              process.env.REACT_APP_ASSETS_URL +
                              "/mobile/images/next-arrow.svg"
                            }
                            alt="next arrow"
                          />
                        </div>
                      </div>
                      <p className="available-coin">
                        {textData?.pay_mob_avail_coins_text} {total_user_coins}
                      </p>
                    </div>
                    <aside
                      style={{
                        pointerEvents: pushbar?.verifyRedeemCoins
                          ? "auto"
                          : "none",
                      }}
                      className={
                        pushbar?.verifyRedeemCoins
                          ? "pushbar from_bottom ht77 opened"
                          : "pushbar from_bottom ht77"
                      }
                    >
                      <div className="pop-up-wrapper">
                        <div className="upi-popup-content">
                          <div className="p-paymenticon">
                            <div className="p-payicon-border">
                              <img
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/images/star_icon.png"
                                }
                                alt="coins icon"
                                className="p-staricon "
                              />
                            </div>
                          </div>
                          <p className="p-txt1">
                            {textData?.pay_redeem_coins_popup_mob}
                          </p>
                          <p className="p-upiid fontsize">
                            {textData?.pay_redeem_coins_popup_mob_subtext1}{" "}
                            {data?.coins}{" "}
                            {textData?.pay_redeem_coins_popup_mob_subtext2}
                          </p>
                          <div className="p-paybtnbox">
                            <div
                              className="p-cancel-btn pointer"
                              data-name="verifyRedeemCoins"
                              value={false}
                              onClick={(e) => handlePushbar(e)}
                            >
                              {textData?.pay_popup_cancel}
                            </div>
                            <div
                              className="p-blue-btn pointer"
                              data-name="verifyRedeemCoins"
                              value="true"
                              plan_details_id={data?.plan_details_id}
                              payment_id={data?.payment_id}
                              onClick={(e) => handleOtherPayments(e)}
                            >
                              {textData?.pay_redeem_popup_yes_continue}
                            </div>
                          </div>
                        </div>
                      </div>
                    </aside>
                  </>
                )} */}
              {
                // (data?.payment_name !== "Credit Card" && data?.payment_name !== "Hungama Coins" && showGoogleWallet)
                data?.payment_name === "Google Wallet" &&
                showGoogleWallet &&
                data?.payment_logo && (
                  <div
                    className="pay-mode-box"
                    key={i}
                    plan_details_id={data?.plan_details_id}
                    payment_id={data?.payment_id}
                    onClick={(e) => handleOtherPayments(e)}
                  >
                    <div
                      className="google-play-store-box flex1"
                      plan_details_id={data?.plan_details_id}
                      payment_id={data?.payment_id}
                      onClick={(e) => handleOtherPayments(e)}
                    >
                      <div
                        className="gpsc"
                        plan_details_id={data?.plan_details_id}
                        payment_id={data?.payment_id}
                        onClick={(e) => handleOtherPayments(e)}
                      >
                        <img
                          src={data?.payment_logo}
                          alt="play store"
                          className="gplayimg"
                          plan_details_id={data?.plan_details_id}
                          payment_id={data?.payment_id}
                          onClick={(e) => handleOtherPayments(e)}
                        />{" "}
                        {/* {data?.payment_name} */}
                        {textData?.pay_google_wallet}
                      </div>
                      <div
                        className="nextarrow pointer"
                        plan_details_id={data?.plan_details_id}
                        payment_id={data?.payment_id}
                        onClick={(e) => handleOtherPayments(e)}
                      >
                        <span className="hungama-coins">{data?.coins}</span>
                        <img
                          src={
                            process.env.REACT_APP_ASSETS_URL +
                            "/mobile/images/next-arrow.svg"
                          }
                          alt="next arrow"
                          plan_details_id={data?.plan_details_id}
                          payment_id={data?.payment_id}
                          onClick={(e) => handleOtherPayments(e)}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          ))}
    </>
  );
};
export default Payments;
