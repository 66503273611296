//import React from "react";
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";

import { amplitudeBtnEvent, ampRabbitMQBtnEvent } from "../../utils";
import axios from "axios";

const Wallets = ({
  identity,
  textData,
  wallets,
  urlparams,
  coupon_code,
  pushbar,
  setPushbar,
  product_id,
  platform_id,
}) => {
  //console.log(wallets,'payments');
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  const [setUserBalance, setBalance] = useState("");
  const [setLinkBalance, linkBalance] = useState("");
  // created this function specifically for amazon popup
  const handleWalletContinue = (e) => {
    let payment_id = e?.target?.getAttribute("payment_id");
    let plan_details_id = document?.getElementById("plan_details_id").value;

    // amplitudeBtnEvent(identity, "Btn_Submit_Wallet", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_Wallet"
    );
    let chrome_custom_tab = "";
    if (payment_id === "9" || payment_id === 9) {
      chrome_custom_tab = "cct";
    }
    const storedWebsite = localStorage.getItem("website");
    if(storedWebsite == 'true' || storedWebsite == true){
window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?tab=${chrome_custom_tab}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&${urlparams}&coupon_code=${coupon_code}&website=true`;
    }
    else{
    window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?tab=${chrome_custom_tab}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&${urlparams}&coupon_code=${coupon_code}`;
    }
  };
  const gaEventTracker = useAnalyticsEventTracker("Wallet and More");

  const handleWallet = (data) => {
    // amplitudeBtnEvent(identity, "Btn_Submit_Wallet", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_Wallet"
    );
    const { payment_id, plan_details_id } = data;
    var item_value = sessionStorage?.getItem("plan_name_val");
    gaEventTracker(data.payment_name, item_value);
    sessionStorage.removeItem("plan_name_val");
    //console.log(data.payment_name);
    // return;
    document.getElementById("plan_details_id").value = plan_details_id;
    // calling this if userbalance is not set and payment is from amazon
    if (
      (data?.payment_id === "9" || data?.payment_id === 9) &&
      setUserBalance !== undefined
    ) {
      hungamaAmazonPay("true");
    } else if (
      (data?.payment_id === "9" || data?.payment_id === 9) &&
      setUserBalance === undefined
    ) {
      let chrome_custom_tab = "cct";
      const storedWebsite = localStorage.getItem("website");
      if(storedWebsite == 'true' || storedWebsite == true){
     window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?tab=${chrome_custom_tab}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&${urlparams}&coupon_code=${coupon_code}&website=true`;
    }else{
       window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?tab=${chrome_custom_tab}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&${urlparams}&coupon_code=${coupon_code}`;
    }
 } else {
      let chrome_custom_tab = "";
      const storedWebsite = localStorage.getItem("website");
      if(storedWebsite == 'true' || storedWebsite == true){
 window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?tab=${chrome_custom_tab}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&${urlparams}&coupon_code=${coupon_code}&website=true`;
      }
      else{
       window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?tab=${chrome_custom_tab}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&${urlparams}&coupon_code=${coupon_code}`;
      }
    }
  };
  const handlePushbarAmz = (e) => {
    let value = e;
    const name = "verifyAmazonPay";

    setPushbar((prev) => {
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
  };

  const hungamaAmazonPay = (e) => {
    // amplitudeBtnEvent(
    //   identity,
    //   "Btn_Select_Amazon_Pay",
    //   product_id,
    //   platform_id
    // );
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Select_Amazon_Pay"
    );

    const name = "verifyAmazonPay";
    let value = e;
    setPushbar((prev) => {
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
  };
  // const [pushbar, setPushbar] = useState({ verifyAmazonPay: false });
  const getAmazonBalance = (identity) => {
    const getBalanceAPI = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=9&rtype=get_user_balance&identity=${identity}`;
    // console.log("getBalanceAPI:: " + getBalanceAPI);

    axios
      .get(getBalanceAPI)
      .then((res) => {
        let userBalance = "";
        let userLinkBalance = "";
        userBalance = res?.data?.total;
        if ((userBalance && userBalance !== "") || null || undefined) {
          userBalance = "₹" + userBalance;
        } else {
          userLinkBalance = "Link";
        }

        setBalance(userBalance);
        linkBalance(userLinkBalance);
      })
      .catch((err) => {
        // console.log(err);
      });
    // console.log(formData);
  };

  useEffect(() => {
    // Update the document title using the browser API
    // getAmazonBalance(identity);
    // console.log("setUserBalance :: " + setUserBalance);
  });

  return (
    <>
      {wallets && wallets.length > 0 ? (
        <div className="pay-mode-box">
          <p className="p-t1">{textData?.pay_wallet_label_mob}</p>
          {wallets
            .sort(
              (a, b) =>
                parseFloat(a?.display_order) - parseFloat(b?.display_order)
            )
            .map((data, i) => (
              <div
                className="wallet-box flex1"
                onClick={(e) => handleWallet(data)}
                key={i}
              >
                <div className="wallet-icon-box">
                  <img
                    style={{
                      borderRadius: `${data?.payment_id === 8 ? "50%" : ""}`,
                    }}
                    src={data?.payment_logo}
                    alt="paytm"
                    className="pay-mode-img"
                  />
                </div>
                <div className="wallet-offer-box">
                  <span className="paym-name">{data?.payment_name}</span>
                  {data.offer_text && (
                    <span className="paym-discoountbox">
                      {data?.offer_text}
                    </span>
                  )}
                  {data.payment_name === "Amazon Pay" && (
                    <span>&nbsp;{setUserBalance} </span>
                  )}
                </div>
                <div className="walletnext-arrowbox">
                  <div className="wallet-next-arrow">
                    {data?.payment_name === "Amazon Pay" && (
                      <span className="mr5">{setLinkBalance}</span>
                    )}
                    <img
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/images/next-arrow.svg"
                      }
                      alt="next arrow"
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div></div>
      )}
      <aside
        style={{ pointerEvents: pushbar?.verifyAmazonPay ? "auto" : "none" }}
        className={
          pushbar.verifyAmazonPay
            ? "pushbar from_bottom ht77 opened"
            : "pushbar from_bottom ht77"
        }
      >
        <div className="pop-up-wrapper">
          <div className="upi-popup-content">
            <div className="p-paymenticon">
              <div className="p-payicon-border">
                <img
                  alt="Amazon Pay"
                  src="https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/amazon-pay.svg"
                  class="wallet-icon"
                />
              </div>
            </div>
            <p className="p-txt1">{textData?.pay_wallet_amazon_popup}</p>
            <p className="p-upiid fontsize">
              {textData?.pay_amazon_wallet_text_msg}
            </p>
            <div className="p-paybtnbox">
              <div
                className="p-cancel-btn pointer"
                data-name="verifyAmazonPay"
                value={false}
                onClick={(e) => handlePushbarAmz(e)}
              >
                <i
                  data-name="verifyAmazonPay"
                  value={false}
                  onClick={(e) => handlePushbarAmz(e)}
                  className="icon-Remove button-icon"
                ></i>
                {textData?.pay_popup_cancel}
              </div>
              <div
                className="p-blue-btn pointer"
                data-name="verifyAmazonPay"
                value="true"
                payment_id="9"
                onClick={(e) => handleWalletContinue(e)}
              >
                <i
                  data-name="verifyAmazonPay"
                  value="true"
                  payment_id="9"
                  onClick={(e) => handleWalletContinue(e)}
                  className="icon-tick button-icon"
                ></i>
                {textData?.pay_redeem_popup_yes_continue}
              </div>
            </div>
          </div>
        </div>
      </aside>
      <input type="hidden" name="plan_details_id" id="plan_details_id" />
    </>
  );
};
export default Wallets;
